.Modal:not(:empty) {
  display: block;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

.Modal__bodyOpen {
  overflow: hidden;
}

.ModalOverlay {
  opacity: 0;
  transition: opacity 0.1s, transform 0.15s;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalOverlay__afterOpen {
  opacity: 1;
  transform: scale(1);
}

.ModalOverlay__beforeClose {
  opacity: 0;
  transform: scale(1.5);
}

.ReactModal__Content {
  margin: 0 10px;
  min-width: 300px;
}
